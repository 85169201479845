import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchAdminEncyclopedias, updateEncyclopedias } from '../../../redux/apiCalls/encyclopediaApiCall';
import JoditEditor from 'jodit-react';

export default function UpdateEncycoScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { encyclopedias } = useSelector(state => state.encyclopedia)
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchAdminEncyclopedias())
    }, [])

    const  encyclopedia  = encyclopedias.find((item) => item._id === id);

    const [title, setTitle] = useState(encyclopedia.title)
    const [summary, setSummary] = useState(encyclopedia.summary)
    const [link, setLink] = useState(encyclopedia.link)

    const subjectId = encyclopedia._id;


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (link.trim() === "") return toast.error("رابط التحميل مطلوب");

        dispatch(updateEncyclopedias(subjectId, {title, summary, link}));
        navigate("/dashboard/encyclopedia");
    }

    return (
        <div className='updateScreen' key={encyclopedia?.id}>
            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                <label htmlFor='summary'>نبذة</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                <label htmlFor='link'>رابط التحميل</label>
                <textarea type='text' id='link' name='link' value={link} onChange={(e) => setLink(e.target.value)}></textarea>
                <button className='sbmtBtn' type='submit'>حفظ التعديلات</button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
