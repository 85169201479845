import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css"

export const AboutUs = () => {

  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

  return (
    <div className="aboutUs">
      <div className="div-2">
        <div className="overlap-2">
          <img className="element-3" alt="Element" src="/imgs/1.png" />
          <img className="element-4" alt="Element" src="/imgs/3.png" />
          <div className="text-wrapper-2">رؤية مساحة قيمة المجتمعية</div>
        </div>
        <div className="secs">
          <div className="sec">
            <h2>مساحة قيمة المجتمعية</h2>
            <p>مساحة مجتمعية تفاعلية غير ربحية تأسست يوليو 2017 تحوي بين جدرانها مختلف الآراء والافكار، تتخذ من التناظر طريقَا وسبيلاً لعرض أهم القضايا وحل أبرز مشكلات مجتمعنا بأسلوب علمي، وطرح حلول واقعية والعمل على تنفيذها لإحياء المجتمع وجعله أفضل. نسعى لنشر وترسيخ ثقافة المناظرات، فلغتنا العربية نعتز ونتحدث بها، البحث العلمي الدقيق هو سبيل رصدنا لأهم المشكلات، وتحمل المسئولية والعمل الجماعي والالتزام أدوات نجاحنا لقيمة ننشرها ونرسخها من أجل مجتمع فاضل.</p>
          </div>
          <div className="sec">
            <h2>رؤيتنا  </h2>
            <p>    الريادة في نشر وترسيخ ثقافة المناظرات بالمجتمع المصري في عام 2025.        </p>
          </div>
          <div className="sec">
            <h2>رسالتنا  </h2>
            <p>نشر وترسيخ ثقافة المناظرات في المجتمع بإبراز أهم القضايا والمشكلات المجتمعية ووضع تصور لأليات حل لها والعمل على تنفيذها.</p>
          </div>
        </div> <br></br>
        <img className="aboutUsImg" alt="aboutUs" src="/imgs/aboutUs.png"></img> <br></br>
        <div className="goalsCont">
          <h1>الأهداف</h1>
          <div className="goals">
            <div className="goal">
              <h3>الهدف الأول</h3>
              <div className="cont">العمل على نشر وترسيخ ثقافة المناظرة في المجتمع.</div>
            </div>
            <div className="goal">
              <h3>الهدف الثاني</h3>
              <div className="cont">تعزيز التواصل باللغة العربية والحفاظ على هوية لغتنا وثقافتنا.</div>
            </div>
            <div className="goal">
              <h3>الهدف الثالث</h3>
              <div className="cont">زيادة وعي أفراد المجتمع ومعرفتهم بأهم القضايا والمشكلات المجتمعية تماشيا مع أهداف التنمية المستدامة المحلية والعالمية.</div>
            </div>
            <div className="goal">
              <h3>الهدف الرابع</h3>
              <div className="cont">توفير بيئة صحية للحوار وتبادل وجهات النظر المختلفة واستهداف كافة الفئات العمرية.</div>
            </div>
            <div className="goal">
              <h3>الهدف الخامس</h3>
              <div className="cont">وضع تصور لآليات حل لتلك المشكلات من خلال دراسة مفصلة وبحث علمي مقدم.</div>
            </div>
            <div className="goal">
              <h3>الهدف السادس</h3>
              <div className="cont">جعل المناظرات مادة ونشاط يتم التدريب عليه في المناهج التعليمية.</div>
            </div>
          </div>
        </div> <br></br>
        <div className="qimaVis">
          <h1>رؤية مصر 2030 x رؤية مساحة قيمة المجتمعية </h1>
          <div>
            <p>تعمل مساحة قيمة المجتمعية على المضي قدما نحو المساهمة قي تحقيق رؤية مصر 2030 من خلال التركيز علي بعض الأهداف في أجندة مصر للتنمية المستدامة، والعمل علي التأكيد عليها وعلى أهميتها والسعي نحو المساهمة في تحقيق بعض منها من خلال الأفكار والفعاليات والقضايا التي تقدمها مساحة قيمة المجتمعية، والتي ترتكز في المقام الأول على نشر وترسيخ ثقافة المناظرات في المجتمع والتي تتخدها المساحة أفضل وسيلة للتعريف بأهم القضايا والموضوعات التي ترتبط ارتباطا وثيقا بتحقيق أهداف التنمية المستدامة الموضوعة في أجندة مصر في رؤيتها 2030  وكذلك الأهداف العالمية للتنمية المستدامة ومن ضمنها الآتي: </p>
            <ul>
              <li>عقد مناظرات عامة عن قضايا المرأة وحقوقها وإنشاء جامعات في الأماكن النائية والقضايا المرتبطة بذوي الإحتياجات الخاصة ودمجهم في المجتمع وقضايا الشباب المختلفة وسوق العمل وقضايا ذوي الهمم واندماجهم بالمجتمع وذلك تماشيا مع الهدف الثاني من أهداف التنمية المستدامة والخاص بالعدالة والإندماج الإجتماعي والمشاركة، والذي يسعى لتحقيق المساواة في الحقوق والفرص وتوفير الموارد في كل المناطق الجغرافية وتمكين المرأة والشباب ودعم مشاركة كل الفئات في التنمية، وكذلك تحقيقا للأهداف العالمية للتنمية المستدامة الخاصة بالمساواة بين الجنسين والحد من أوجه عدم المساواة.</li>
              <li>عقد مناظرات عامة عن قضايا العشوائيات وقضايا التعليم المختلفة كنظام التنسيق بالثانوية العامة وتطوير التعليم الجامعي والتخصصات الدراسية وربطها بسوق العمل وموضوعات التحول الرقمي بالقطاعات الحكومية وتطوره وذلك بالتوازي مع الهدف الاول من أهداف التنمية المستدامة الخاص بالإرتقاء بجودة حياة المواطن المصري، وتحسين مستوى معيشته بالحد من الفقر وتوفير منظومة متكاملة للحماية الإجتماعية وإتاحة التعليم وضمان جودته، وتحسين البنية التحتية وتطوير البنية التحتية الرقمية وكذلك مع الأهداف العالمية للتنمية المستدامة وهي التعليم الجيد والقضاء على الفقر والجوع.</li>
              <li>عقد مناظرات عامة عن قضايا العمل وإستخدام التكنولوجيا في الأعمال والتحول الرقمي في القطاعات المختلفة وتأثيرها الإيجابي، وطرق التنمية الإقتصادية المختلفة والتعامل مع الأزمات الاقتصادية، وساعات العمل المقررة وقضايا سن التقاعد في العمل وذلك تماشيا مع الهدف الثالث من أهداف التنمية المستدامة والخاص بإقتصاد تنافسي ومتنوع قائم على المعرفة وتحقيق التحول الرقمي ورفع درجة مرونة وتنافسية الإقتصاد وزيادة معدلات التشغيل وفرص العمل اللائق وكذلك بالتوازي مع الأهداف العالمية للتنمية المستدامة وهي العمل اللائق ونمو الإقتصاد. </li>
              <li>عقد مناظرات عامة عن قضايا القوة الناعمة للدولة لمواجهة التطرف وقضايا مكافحة الإرهاب والأمن المائي وإلقاء الضوء علي قضايا الوضع الإقليمي سواء المرتبطة بالشرق الأوسط وشمال أفريقيا وذلك بالتوازي مع الهدف السابع من أهداف التنمية المستدامة والخاص بالسلام والأمن المصري لما تضعه الدولة من الأمن أهمية وأولوية قصوى، وضمان الأمن الغذائي والمائي ومكافحة الإرهاب وكذلك مع الهدف الثامن الخاص بتعزيز الريادة المصرية على المستويين الاقليمي والدولي والذي يأثي مع الأهداف العالمية للتنمية المستدامة المتمثلة في السلام والعدل والمؤسسات القوية. </li>
              <li>عقد مناظرات عامة عن قضايا المعرفة والبحث العلمي ونتائج البحث العلمي وضرورة توافره للجميع للاستفادة من لارتباطه بالتنمية وذلك ماشيا مع الهدف الرابع من أهداف التنمية المستدامة والخاص بالمعرفة والابتكار والبحث العلمي وكذلك بالتوازي مع الأهداف العالمية للتنمية المستدامة والخاصة بتوظيف الابتكارات لمعالجة الازمات التنموية. </li>
            </ul>
            <p>المناظرات التي تقدمها مساحة قيمة المجتمعية لا تتوقف إطلاقا عند التعريف بتلك القضايا والمشكلات وطرحها بشكل مختلف، ولكن آيضا بتقديم حلول عملية لها والعمل علي عرضها على صناع القرار لمساعدتهم في تحقيقها، ومن ضمن ذلك ندوة عامة في ساقية الصاوي بعنوان المناظرات وقضايا المجتمع – العشوائيات نموذجا وتم عقد مناظرة عامة بعنوان إن إستثمار آراضي العشوائيات هو الحل الامثل لمواجهة تلك الازمة وتم تقديم ملف بناء القضية بالحلول المقدمة للسيد نائب وزير الاسكان الذي كان حاضرا لتلك الندوة والمناظرة.</p>
          </div>
        </div>
        <Header className="header-instance" element="image.png" line="line-1-2.svg" vector="image.svg" />
      </div>
      <Footer
        className="footer-instance"
        element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
        email="email-2.svg"
        img="line-7-2.svg"
        line="line-6-2.svg"
      />
    </div>
  );
};
