import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { showNewSubject } from "../redux/apiCalls/newApiCall";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';

export const NewSubject = () => {
  const dispatch = useDispatch();
  const { anew } = useSelector(state => state.new)
  const { id } = useParams();

  const imgStyle = {
    width: `${anew?.imageWidth}%`,
  }
  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(showNewSubject(id))
  }, [id]);

  return (
    <div className="subject">
      <div className="div-2">
        {
          <div className="overlap-2" key={anew?.id}>
            <div className="overlap-3">
              <div className="text-wrapper-2">{parse(`${anew?.title}`)}</div>
            </div>
            <div className="mainSub">
              <div className="date">{moment(anew?.date).format('LL')}</div>
              <img className="subImg" style={imgStyle} alt={anew?.image.original_filename} src={anew?.image.url}></img>
              <div className="p">
                {parse(`${anew?.topic}`)}
              </div>
            </div>
          </div>
        }
        <div className="fotcont">
        </div>
        <Header className="header-instance" element="image.png" line="line-1-2.svg" vector="image.svg" />
      </div>
      <Footer
        className="footer-instance"
        element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
        email="email-2.svg"
        img="line-7-2.svg"
        line="line-6-2.svg"
      />
    </div>
  );
};
