import { newActions } from "../slices/newSlice";
import request from "../../utils/request";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


// Fetch News
export function fetchNews() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/news")
            dispatch(newActions.setNews(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Update News
export function updateNews(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.put(`/adminDashboard/news/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(newActions.setNew(data));
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Delete News
export function deleteNews(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.delete(`/adminDashboard/news/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(newActions.deleteNew(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add News
export function addNews(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const token = user.token;
            dispatch(newActions.setLoading());
            await request.post("/adminDashboard/addNew", info, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(newActions.setIsNewCreated());
            setTimeout(() => dispatch(newActions.clearIsNewCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(newActions.clearLoading());
        }
    }
}

// Fetch Admin News
export function fetchAdminNews() {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            const { data } = await request.get(`/adminDashboard/${userId}/news`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            dispatch(newActions.setNews(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Show New Subject
export function showNewSubject(id) {
    return async (dispatch) => {
        try {
            const { data } = await request.get(`/news/${id}`)
            dispatch(newActions.setNew(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}