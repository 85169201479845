import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRumors } from '../../redux/apiCalls/rumorApiCall';
import parse from 'html-react-parser';

export default function RumorDroplist() {
    const [dropdown, setdropdown] = useState(false)

    const [mouseHover, setMouseHover] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdown && !dropdownRef.current.contains(event.target)) {
                setdropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdown]);


    const handleMouseEnter = () => {
        setMouseHover(true);
    };

    const handleMouseLeave = () => {
        setMouseHover(false);
    };

    const toggleDropdown = () => {
        setdropdown(!dropdown)
    }

    const dispatch = useDispatch();
    const { rumors } = useSelector(state => state.rumor);

    useEffect(() => {
        dispatch(fetchRumors());
    }, [])

    const [myRumor, setMyRumor] = useState(rumors[0])
    const imgStyle = {
        width: `${myRumor?.imageWidth}%`,
    }
    return (
        <div className="year-drop-list">
            <div className='yearsDL'>
                <div className='rumorcont'>
                    {rumors.map((item) => (
                        <div className='rum' key={item?.id}>
                            <img className="subImg" style={{ width: `${item?.imageWidth}%` }} alt={item?.image.original_filename} src={item?.image.url}></img>
                            <div className='subCon'>
                                <div className='tit'>{parse(`${item?.title}`)}</div>
                                <div>{parse(`${item?.subject}`)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div >
        </div >
    );

};