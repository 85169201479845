import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner'
import { addRumors } from '../../../redux/apiCalls/rumorApiCall';
import JoditEditor from 'jodit-react';


export default function AddRumorScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isRumorCreated } = useSelector(state => state.rumor);
    const editor = useRef(null);

    const [title, setTitle] = useState("");
    const [summary, setSummary] = useState("");
    const [image, setImage] = useState(null);
    const [imageWidth, setWidth] = useState("");
    //const [video, setVideo] = useState(null);
    const [subject, setSubject] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (subject.trim() === "") return toast.error("الموضوع مطلوب");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        //formData.append('video', video);
        formData.append('subject', subject);
        formData.append('image', image);
        formData.append('imageWidth', imageWidth);


        dispatch(addRumors(formData));
    }

    useEffect(() => {
        if (isRumorCreated) {
            navigate("/dashboard/rumors");
        }
    }, [isRumorCreated, navigate]);

    return (
        <div className='updateScreen'>
            {
                <form className='updateForm' onSubmit={onSubmit}>
                    <label htmlFor='title'>العنوان</label>
                    <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                    <label htmlFor='summary'>نبذة</label>
                    <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                    <div className='imgCont'>
                        <div className='sec'>
                            <label htmlFor='image'>الصورة:</label>
                            <input type='file' id='image' name='image' onChange={(e) => setImage(e.target.files[0])} />
                        </div>
                        <div className='sec'>
                            <label htmlFor='w' className='imgsizeL'>عرض الصورة:</label>
                            <div className='imgCont2'>
                                <input id='w' name='w' className='imgsizeI' type='number' value={imageWidth} onChange={(e) => setWidth(e.target.value)}></input>
                                <div className='percent'>%</div>
                            </div>
                        </div>
                    </div><label htmlFor='subject'>الموضوع</label>
                    <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={subject} onChange={content => setSubject(content)} />
                    <button className='sbmtBtn' type='submit'>
                        {
                            loading ? <RotatingLines
                                visible={true}
                                height="30"
                                width="30"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /> : "أضف"
                        }
                    </button>
                </form>
            }
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
