import React, { useState, useEffect, useRef } from 'react';
import Dropdown1 from './dropdwon1';
import Dropdown2 from './dropdwon2';
import { Link } from 'react-router-dom';

export default function DropdownSec() {
    const [showDropdowns, setShowDropdowns] = useState(false);
    const [showDropdowns1, setShowDropdowns1] = useState(false);
    const [showDropdowns2, setShowDropdowns2] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                !dropdownRef.current &&
                dropdownRef1.current.contains(event.target) &&
                dropdownRef2.current.contains(event.target)
            ) {
                setShowDropdowns(false)
            }
            if (
                dropdownRef1.current &&
                !dropdownRef1.current.contains(event.target)
            ) {
                setShowDropdowns1(false);
            }
            if (
                dropdownRef2.current &&
                !dropdownRef2.current.contains(event.target)
            ) {
                setShowDropdowns2(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const toggleDropdowns = () => {
        setShowDropdowns(!showDropdowns);
        setShowDropdowns1(false);
        setShowDropdowns2(false)
    };


    const toggleDropdowns1 = () => {
        setShowDropdowns1(!showDropdowns1);
    };

    const toggleDropdowns2 = () => {
        setShowDropdowns2(!showDropdowns2);
    };

    return (
        <div className='dropdownHeader'>
            <img className="forSmallScreens" onClick={toggleDropdowns} src="/imgs/77.png" />
            <div ref={dropdownRef}>
                {showDropdowns && (

                    <ul>
                        <li className="cls">
                            <div onClick={toggleDropdowns1}>
                                من نحن
                            </div>
                            <div ref={dropdownRef1}>
                                {showDropdowns1 && (
                                    <div>
                                        <Dropdown1 />
                                    </div>
                                )}
                            </div>
                        </li>
                        <Link className="a cls" to={"/rumor-monitoring-centre"}>مركز قيمة لرصد الشائعات</Link>
                        <Link className="a cls" to={"/library"}>مكتبة قيمة الإلكترونية</Link>
                        <Link className="a cls" to={"/encyclopedia"}>موسوعة قيمة الإلكترونية</Link>
                        <li className="cls">
                            <div onClick={toggleDropdowns2}>مناظرات
                            </div>
                            <div ref={dropdownRef2}>
                                {showDropdowns2 && (
                                    <div>
                                        <Dropdown2 />
                                    </div>
                                )}
                            </div>
                        </li>
                        <Link className="a cls" to={'/news'}>أخبار</Link>
                    </ul>
                )}
            </div >
        </div>
    );
};