import request from "../../utils/request";
import { toast } from "react-toastify";
import { debateActions } from "../slices/debateSlice";

// Fetch Debates
export function fetchDebates() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/debates")
            dispatch(debateActions.setDebates(data));
            localStorage.setItem("debates", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Show Debate Subject
export function showDebateSubject(id) {
    return async (dispatch) => {
        try {
            dispatch(debateActions.setDebate(null));
            const { data } = await request.get(`/debates/${id}`)
            dispatch(debateActions.setDebate(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Fetch Admin Debates
export function fetchAdminDebates() {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            const { data } = await request.get(`/adminDashboard/${userId}/debates`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            dispatch(debateActions.setDebates(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Update Debates
export function updateDebates(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.put(`/adminDashboard/debates/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(debateActions.setDebate(data));
            setTimeout(() => dispatch(debateActions.clearIsDebateCreated()), 2000);
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Delete Debates
export function deleteDebates(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            console.log(subjectId)
            const { data } = await request.delete(`/adminDashboard/debates/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(debateActions.deleteDebate(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Debates
export function addDebates(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const token = user.token;
            dispatch(debateActions.setLoading());
            await request.post("/adminDashboard/addDebate", info, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(debateActions.setIsDebateCreated());
            setTimeout(() => dispatch(debateActions.clearIsDebateCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(debateActions.clearLoading());
        }
    }
}