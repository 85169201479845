import { createSlice } from "@reduxjs/toolkit";

// newSlice states and actions 
const newSlice = createSlice({
    name:"new",
    initialState: {
        news: [],
        anew: null,
        loading: false,
        isNewCreated: false,
    },
    reducers: {
        setNews(state, action) {
            state.news = action.payload;
        },
        setNew(state, action) {
            state.anew = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsNewCreated(state) {
            state.isNewCreated = true;
            state.loading = false;
        },
        clearIsNewCreated(state) {
            state.isNewCreated = false;
        },
        deleteNew(state, action) {
            state.news = state.news.filter(i => i._id !== action.payload);
        },
    }
});

const newReducer = newSlice.reducer;
const newActions = newSlice.actions;

export {newActions, newReducer}