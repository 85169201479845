import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAdminNews } from '../../../redux/apiCalls/newApiCall';
import { ToastContainer, toast } from 'react-toastify';
import { updateNews } from '../../../redux/apiCalls/newApiCall';
import JoditEditor from 'jodit-react';

export default function UpdateNewScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { news } = useSelector(state => state.new)
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchAdminNews())
    }, [])

    const anew = news.find((item) => item._id === id);

    const [title, setTitle] = useState(anew.title)
    const [summary, setSummary] = useState(anew.summary)
    const [image, setImage] = useState(null)
    const [topic, setTopic] = useState(anew.topic)
    const [imageWidth, setWidth] = useState(anew.imageWidth);

    const subjectId = anew._id;


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (topic.trim() === "") return toast.error("الموضوع مطلوب");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        formData.append('image', image);
        formData.append('topic', topic);
        formData.append('imageWidth', imageWidth);

        dispatch(updateNews(subjectId, formData));
        toast.success("تم التعديل بنجاح");

        navigate("/dashboard/news");
    }

    return (
        <div className='updateScreen' key={anew?.id}>

            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                <label htmlFor='summary'>نبذة</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                <img src={anew?.image.url}></img>
                <div className='imgCont'>
                    <div className='sec'>
                        <label htmlFor='image'>الصورة:</label>
                        <input type='file' id='image' name='image' onChange={(e) => setImage(e.target.files[0])} />
                    </div>
                    <div className='sec'>
                        <label htmlFor='w' className='imgsizeL'>عرض الصورة:</label>
                        <div className='imgCont2'>
                            <input id='w' name='w' className='imgsizeI' type='number' value={imageWidth} onChange={(e) => setWidth(e.target.value)}></input>
                            <div className='percent'>%</div>
                        </div>
                    </div>
                </div>
                <label htmlFor='topic'>الموضوع</label>
                <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={topic} onChange={content => setTopic(content)} />
                <button className='sbmtBtn' type='submit'>حفظ التعديلات</button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
