import React, { useEffect } from "react";
import "../../App.css";
import { Link } from "react-router-dom";


export const SidebarDash = () => {
    return (

        <div className="overlap-4">
            <div className="DBTitle">
                <div className="text-wrapper-3">لوحة التحكم</div>
                <img className="dashboard-customize" alt="Dashboard customize" src="/imgs/55.png" />
            </div>
            <ul className="dashboardList">
                <Link className="navAdmin" to={"/dashboard/news"}>
                    <li className="div-wrapper">
                        الأخبار
                    </li>
                </Link>
                <Link className="navAdmin" to={"/dashboard/libraries"}>
                    <li className="div-wrapper">
                        المكتبة
                    </li>
                </Link>
                <Link className="navAdmin" to={"/dashboard/encyclopedia"}>
                    <li className="div-wrapper">
                        الموسوعة
                    </li>
                </Link>
                <Link className="navAdmin" to={"/dashboard/debates"}>
                    <li className="div-wrapper">
                        المناظرات
                    </li>
                </Link>
                <Link className="navAdmin" to={"/dashboard/rumors"}>
                    <li className="div-wrapper">
                        الشائعات
                    </li>
                </Link>
            </ul>

        </div>
    )
}