import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchAdminRumors, updateRumors } from '../../../redux/apiCalls/rumorApiCall';
import JoditEditor from 'jodit-react';

export default function UpdateRumorScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { rumors } = useSelector(state => state.rumor)
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchAdminRumors())
    }, [])

    const rumor = rumors.find((item) => item._id === id);

    const [title, setTitle] = useState(rumor.title)
    const [summary, setSummary] = useState(rumor.summary)
    const [year, setYear] = useState(rumor.year);
    const [subject, setSubject] = useState(rumor.subject)
    const [image, setImage] = useState(null)
    const [imageWidth, setWidth] = useState(rumor.imageWidth);

    const subjectId = rumor._id;

    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (year.trim() === "") return toast.error("العام مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (subject.trim() === "") return toast.error("الموضوع مطلوب");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('year', year);
        formData.append('summary', summary);
        formData.append('subject', subject);
        formData.append('image', image);
        formData.append('imageWidth', imageWidth);

        dispatch(updateRumors(subjectId, formData));
        navigate("/dashboard/rumors");
    }

    return (
        <div className='updateScreen' key={rumor?.id}>

            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                <label htmlFor='year'>العام</label>
                <input type='text' id='year' name='year' value={year} onChange={(e) => setYear(e.target.value)}></input>
                <label htmlFor='summary'>نبذة</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                <img src={rumor?.image.url}></img>
                <div className='imgCont'>
                    <div className='sec'>
                        <label htmlFor='image'>الصورة:</label>
                        <input type='file' id='image' name='image' onChange={(e) => setImage(e.target.files[0])} />
                    </div>
                    <div className='sec'>
                        <label htmlFor='w' className='imgsizeL'>عرض الصورة:</label>
                        <div className='imgCont2'>
                            <input id='w' name='w' className='imgsizeI' type='number' value={imageWidth} onChange={(e) => setWidth(e.target.value)}></input>
                            <div className='percent'>%</div>
                        </div>
                    </div>
                </div>
                <label htmlFor='subject'>الموضوع</label>
                <JoditEditor ref={editor} id='topic' name='topic' placeholder='أدخل الموضوع.....' value={subject} onChange={content => setSubject(content)} />
                <button className='sbmtBtn' type='submit'>حفظ التعديلات</button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
