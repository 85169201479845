import React from 'react'
import { Link } from 'react-router-dom'

export default function Dropdown() {
    return (
        <div>
            <div className="dropdown2 dd">
                <ul>
                    <Link className="ddA" to={'/about-debates'}><li className="dropdown">عن المناظرات</li></Link>
                    <Link className="ddA" to={'/debates'}><li className="dropdown">مناظرات قيمة</li></Link>
                </ul>
            </div>
        </div>
    )
}
