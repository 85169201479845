import React, { useEffect, useState } from "react";
import { Footer } from "../footer";
import { Header } from "../header";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteNews, fetchAdminNews } from "../../redux/apiCalls/newApiCall";
import { Link, Navigate } from "react-router-dom";
import { SidebarDash } from "./sidebarDash";
import swal from 'sweetalert'
import moment from 'moment';
import { ToastContainer } from "react-toastify";
import parse from 'html-react-parser';

export const DashboardNews = () => {
    const dispatch = useDispatch();
    const { news } = useSelector(state => state.new);

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        dispatch(fetchAdminNews())
    }, [])

    return (
        <div className="dashboard">
            <Header
                className="design-component-instance-node-2"
                element="image.png"
                line="line-1-2.svg"
                vector="vector-3.svg"
            />
            <div className="div-2">
                <SidebarDash />
                <div className="mainCont">
                    <Link to={"/dashboard/addNew"}>
                        <div className="overlap-9">
                            أضف خبر
                            <img className="create" alt="Create" src="/imgs/44.png" />
                        </div>
                    </Link>
                    <div className="text-wrapper-11">الأخبار</div>
                    <div className="cont">
                        {news.map((item) => (
                            <div className="overlap-10" key={item?.id}>
                                <div className="overlap-11">
                                    <div className="component">
                                        <Link to={`/dashboard/updateNew/${item?._id}`}>
                                            <div className="overlap">
                                                <p className="text-wrapper">تعديل
                                                </p>
                                                <img className="vector" alt="Vector" src="/imgs/11.png" />
                                            </div>
                                        </Link>
                                        <Link to={`/new/${item?._id}`}>
                                            <div className="overlap1">
                                                <div className="text-wrapper">عرض
                                                    <img className="eye" alt="Eye" src="/imgs/33.png" />
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="overlap2" onClick={() => {
                                            swal({
                                                title: "هل أنت متأكد",
                                                text: "بمجرد الحذف، لن تتمكن من الاستعادة!",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                            })
                                                .then((willDelete) => {
                                                    if (willDelete) {
                                                        dispatch(deleteNews(item?._id))
                                                    } else {
                                                        swal("لم يتم الحذف");
                                                    }
                                                });
                                        }}>
                                            <div className="text-wrapper1">حذف
                                                <img className="vector-2" alt="Vector" src="/imgs/22.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="date">{moment(item.date).format('LL')}</div>
                                <div className="overlap-12">
                                    <div className="text-wrapper-10">{parse(`${item.title}`)}</div>
                                </div>
                                <img className="newImg" alt={item.image.original_filename} src={item.image.url}></img>
                                <p className="p">
                                    {parse(`${item.summary}`)}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer
                className="footer-instance"
                element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
                email="email-2.svg"
                img="line-7-2.svg"
                line="line-6-2.svg"
            />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div >
    );
};
