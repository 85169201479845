import React from "react";
/*
import { PlatformFacebookColorNegative } from "./PlatformFacebookColorNegative";
import { PlatformInstagramColorNegative } from "./PlatformInstagramColorNegative";
import { PlatformYoutubeColorNegative } from "./PlatformYoutubeColorNegative";
import { SocialIcons } from "./SocialIcons";*/
import "../App.css";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footercont">
        <div className="social">
          <div className="voo">
            <div className="text-wrapper">تابعنا على حساباتنا</div>
            <div className="iqons">
              <div>
                <a href="https://www.facebook.com/qimacommunity?mibextid=2JQ9oc"><img
                  className="iqon f"
                  alt="Element"
                  src="/imgs/f-removebg-preview.png"
                /></a>
                <a href="https://www.instagram.com/qimadebates/"><img
                  className="iqon i"
                  alt="Element"
                  src="/imgs/i-removebg-preview.png"
                /></a>
              </div>
              <div>
                <img
                  className="iqon y"
                  alt="Element"
                  src="/imgs/y-removebg-preview.png"
                />
                <img
                  className="iqon x"
                  alt="Element"
                  src="/imgs/x-removebg-preview.png"
                />
              </div>
            </div>
            <div className="overlap">
              <div className="overlap-group">
                <div className="line2"></div>
                <div className="div">newinfo.qima@gmail.com</div>
              </div>
              <img className="email" alt="Email" src="/imgs/e-removebg-preview.png" />
            </div>
          </div>
        </div>
        <img
          className="element"
          alt="Element"
          src="/imgs/LOGO.png"
        />
        <div className="line1"></div>
        <div className="dBy"> تم تصميمه وتطويره بواسطة<br></br>
          <a href="https://najm-tech.onrender.com/">نچم تكنو - Najm Tech </a>
        </div>
      </div>
    </div>
  );
};
