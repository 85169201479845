import { createSlice } from "@reduxjs/toolkit";

// debateSlice states and actions 
const debateSlice = createSlice({
    name:"debate",
    initialState: {
        debates: [],
        debate: null,
        loading: false,
        isDebateCreated: false,
    },
    reducers: {
        setDebates(state, action) {
            state.debates = action.payload;
        },
        setDebate(state, action) {
            state.debate = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsDebateCreated(state) {
            state.isDebateCreated = true;
            state.loading = false;
        },
        clearIsDebateCreated(state) {
            state.isDebateCreated = false;
        },
        deleteDebate(state, action) {
            state.debates = state.debates.filter(i => i._id !== action.payload);
        },
    }
});

const debateReducer = debateSlice.reducer;
const debateActions = debateSlice.actions;

export {debateActions, debateReducer}