import { createSlice } from "@reduxjs/toolkit";

// memberSlice states and actions 
const memberSlice = createSlice({
    name:"member",
    initialState: {
        members: [],
        member: null,
        loading: false,
        isMemberCreated: false,
    },
    reducers: {
        setMembers(state, action) {
            state.members = action.payload;
        },
        setMember(state, action) {
            state.member = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsMemberCreated(state) {
            state.isMemberCreated = true;
            state.loading = false;
        },
        clearIsMemberCreated(state) {
            state.isMemberCreated = false;
        },
        deleteMember(state, action) {
            state.members = state.members.filter(i => i._id !== action.payload);
        },
    }
});

const memberReducer = memberSlice.reducer;
const memberActions = memberSlice.actions;

export {memberActions, memberReducer}