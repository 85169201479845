import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchAdminDebates, updateDebates } from '../../../redux/apiCalls/debateApiCall';
import JoditEditor from 'jodit-react';

export default function UpdateDebateScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { debates } = useSelector(state => state.debate);
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchAdminDebates())
    }, [])

    const debate = debates.find((item) => item._id === id);

    const [title, setTitle] = useState(debate.title)
    const [summary, setSummary] = useState(debate.summary)
    const [video, setVideo] = useState(null)
    const [subject, setSubject] = useState(debate.subject)

    const subjectId = debate._id;
    const subjectOldVid = { "publicId": debate.video.publicId, "url": debate.video.url };


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (subject.trim() === "") return toast.error("الموضوع مطلوب");

        const formData = new FormData();
        formData.append('title', title);
        formData.append('summary', summary);
        formData.append('subject', subject);
        formData.append('video', video);

        if (video === null) {
            formData.append('video', subjectOldVid);
        }

        dispatch(updateDebates(subjectId, formData));
        navigate("/dashboard/debates");
    }

    return (
        <div className='updateScreen' key={debate?.id}>

            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                <label htmlFor='summary'>نبذة</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                <label htmlFor='video'>مقطع الفيديو</label>
                <video width="320" height="240" controls>
                    <source src={debate?.video.url} type="video/mp4" />
                </video>
                <input type='file' id='video' name='video' onChange={(e) => setVideo(e.target.files[0])} />
                <label htmlFor='subject'>الموضوع</label>
                <JoditEditor ref={editor} id='subject' name='subject' placeholder='أدخل الموضوع.....' value={subject} onChange={content => setSubject(content)} />
                <button className='sbmtBtn' type='submit'>حفظ التعديلات</button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
