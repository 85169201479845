import React, { useState, useEffect, useRef } from "react";
//import { Hover } from "./Hover";
import "../App.css";
import Dropdown1 from "./partitions/dropdwon1";
import Dropdown2 from "./partitions/dropdwon2";
import SignAdminDD from "./partitions/signAdminDD";
import DropdownSec from "./partitions/dropdwonSec";
import { Link } from "react-router-dom";


export const Header = () => {

  //for dropdown1
  const [dropdown, setdropdown] = useState(false)

  const [mouseHover, setMouseHover] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdown && !dropdownRef.current.contains(event.target)) {
        setdropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);


  const handleMouseEnter = () => {
    setMouseHover(true);
  };

  const handleMouseLeave = () => {
    setMouseHover(false);
  };

  const toggleDropdown = () => {
    setdropdown(!dropdown)
  }

  //for dropdown2
  const [dropdown2, setdropdown2] = useState(false)

  const [mouseHover2, setMouseHover2] = useState(false);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdown2 && !dropdownRef2.current.contains(event.target)) {
        setdropdown2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown2]);


  const handleMouseEnter2 = () => {
    setMouseHover2(true);
  };

  const handleMouseLeave2 = () => {
    setMouseHover2(false);
  };

  const toggleDropdown2 = () => {
    setdropdown2(!dropdown2)
  }


  //for signAdminDD
  const [signAdminDD, setsignAdminDD] = useState(false)

  const [mouseHoverAdmin, setMouseHoverAdmin] = useState(false);
  const dropdownRefAdmin = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (signAdminDD && !dropdownRefAdmin.current.contains(event.target)) {
        setsignAdminDD(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [signAdminDD]);


  const handleMouseEnterAdmin = () => {
    setMouseHoverAdmin(true);
  };

  const handleMouseLeaveAdmin = () => {
    setMouseHover2(false);
  };

  const togglesignAdminDD = () => {
    setsignAdminDD(!signAdminDD)
  }

  return (
    <>
      <div className="header">
        <Link to={"/"}>
          <img
            className="element"
            alt="Element"
            src="/imgs/306085283_3261402177510915_5362796939866915607_n-removebg-preview.png"
          /></Link>
        <div className="headercls">
          <p className="cls" onClick={toggleDropdown}>
            من نحن</p>
          <div
            ref={dropdownRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {dropdown && (mouseHover || dropdownRef.current) && <Dropdown1 />}
          </div>
          <div className="cls"><Link className="a" to={'/rumor-monitoring-centre'}>مركز قيمة لرصد الشائعات</Link></div>
          <div className="cls"><Link className="a" to={"/library"}>مكتبة قيمة الإلكترونية</Link></div>
          <div className="cls"><Link className="a" to={"/encyclopedia"}>موسوعة قيمة الإلكترونية</Link></div>
          <p className="cls" onClick={toggleDropdown2}>
            مناظرات</p>
          <div
            ref={dropdownRef2}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            {dropdown2 && (mouseHover2 || dropdownRef2.current) && <Dropdown2 />}
          </div>
          <div className="cls"><Link className="a" to={'/news'}>أخبار</Link></div>
        </div>
        <DropdownSec />
        <i className="arrow down" onClick={togglesignAdminDD}></i>
        <div
          ref={dropdownRefAdmin}
          onMouseEnter={handleMouseEnterAdmin}
          onMouseLeave={handleMouseLeaveAdmin}
        >
          {signAdminDD && (mouseHoverAdmin || dropdownRefAdmin.current) && <SignAdminDD />}
        </div>
      </div>
    </>
  )
}