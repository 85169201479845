import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembers } from "../redux/apiCalls/memberApiCall";
import RumorDroplist from "./partitions/rumorDroplist";
import parse from 'html-react-parser';

export const RMCenter = () => {
    const dispatch = useDispatch();
    const { members } = useSelector(state => state.member);

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        dispatch(fetchMembers());
    }, [])

    return (
        <div className="rmCenter">
            <div className="div-3">
                <div className="overlap-2">
                    <img className="element-3" alt="Element" src="/imgs/1.png" />
                    <img className="element-4" alt="Element" src="/imgs/3.png" />
                    <div className="text-wrapper-4">نبذة عن المركز</div>
                </div>
                <p className="rmCenterText">
                    مركز قيمة لرصد الشائعات
                    هو مركز تابع لمساحة قيمة المجتمعية، تم تأسيسه في عام 2023 بهدف رصد أهم وأبرز الشائعات المنتشرة على وسائل التواصل الاجتماعي، وتصحيح هذه الإشاعات متخذين في ذلك طريق المناظرات في البحث والتفنيد والتوضيح ،فيتم إرفاق المصادر المستند إليها تصحيح الإشاعة مع الروابط الدالة عليها.<br></br>

                    يهدف المركز إلى أن يصبح رائدا في تصحيح الشائعات وأن يكون على تواصل مباشر مع مستخدمي مواقع التواصل الاجتماعي ليكون مصححا ومرجعا موثوقا يتم الاعتماد عليه من خلال إرسال الخبر المشكوك فيه ليتم تصحيحه من قبل فريق المركز.<br></br>

                    نعمل على تنويع المحتوى الذي يوضح الشائعات ما بين المكتوب والمرئي بغية الوصول إلى فئة كبيرة من مستخدمي شبكات التواصل الاجتماعي.<br></br>

                    <h3>يتألف مركز قيمة لرصد الشائعات أساسا من ثلاثة فرق:</h3>

                    <h4>1- فريق الرصد:</h4> يراقبون عن كثف شبكات التواصل الاجتماعي لرصد الشائعات المثارة وكذلك الأخبار المشكوك في صحتها.<br></br>

                    <h4>2- فريق البحث والتحري:</h4> يقوم الفريق بالتحري عن الإشاعة التي تم رصدها مستعينين بخبرات وطرق البحث المتقدمة للوصول إلى مصدر المعلومات في وقت قياسي،<br></br>

                    <h4>3- الفريق الإعلامي:</h4> مهمته ترجمة ما تم رصده والتحري عنه إلى محتوى ينشر على صفحات مساحة قيمة المجتمعية التي ينشر المركز عليها ما أنتجه من تصحيحات وتوضيحات، يشمل الفريق كتابة المحتوى والتصميم والمونتاج والتصوير.<br></br>
                </p>

                <div className="overlap-3rum">
                    <div className="cont">
                        <div className="ellipse" />
                        <div className="ellipse-2" />
                        <div className="text-wrapper-5">الشائعات</div>
                    </div>
                </div>
                <RumorDroplist />

                <div className="follow">
                تابعوا مركز قيمة لرصد الشائعات على صفحاتنا في مواقع التواصل الإجتماعي
                </div>

                <Header
                    className="header-instance"
                    element="306085283-3261402177510915-5362796939866915607-n-removebg-preview-1-2.png"
                    line="line-1-2.svg"
                    vector="vector-3.svg"
                />
            </div>
            <Footer
                element="image.png"
                email="email-2.svg"
                img="line-7-2.svg"
                line="line-6-2.svg"
            />
        </div>
    );
};
