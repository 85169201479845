import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../../redux/apiCalls/authApiCall';
import { Link } from 'react-router-dom';

export default function SignAdminDD() {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    return (
        <div>
            <div className="signAdminDD dd">
                <ul>
                    {user ?
                        <>
                            <li className='userEmail'>
                                {user?.email}
                            </li>
                            <br></br><br></br>
                            <Link className="ddA" to={'/dashboard/news'}>
                                <li className='dropdown'>
                                    لوحة التحكم
                                </li>
                            </Link>
                            <Link className="ddA" to={'/settings'}>
                                <li className='dropdown'>
                                    الإعدادات
                                </li>
                            </Link>
                            <li onClick={() => dispatch(logoutUser())} className='dropdown'>
                                تسجيل الخروج
                            </li>
                        </> : (
                            <>
                                <Link className="ddA" to={'/login'}><li className='dropdown'>الدخول كمشرف</li></Link>
                            </>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
