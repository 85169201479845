import { createSlice } from "@reduxjs/toolkit";

// librarySlice states and actions 
const librarySlice = createSlice({
    name:"library",
    initialState: {
        libraries: [],
        library: null,
        loading: false,
        isLibraryCreated: false,
    },
    reducers: {
        setLibraries(state, action) {
            state.libraries = action.payload;
        },
        setLibrary(state, action) {
            state.library = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsLibraryCreated(state) {
            state.isLibraryCreated = true;
            state.loading = false;
        },
        clearIsLibraryCreated(state) {
            state.isLibraryCreated = false;
        },
        deleteLibrary(state, action) {
            state.libraries = state.libraries.filter(i => i._id !== action.payload);
        },
    }
});

const libraryReducer = librarySlice.reducer;
const libraryActions = librarySlice.actions;

export {libraryActions, libraryReducer}