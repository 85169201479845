import React from 'react'
import { Link } from 'react-router-dom'

export default function Dropdown1() {
    return (
        <div>
            <div className="dropdown1 dd">
                <ul>
                    <Link className="ddA" to={'/aboutus'}><li className="dropdown">الرؤية</li></Link>
                    <Link className="ddA" to={'/founders'}><li className="dropdown">المؤسسين</li></Link>
                    <Link className="ddA" to={'/organizational-chart'}><li className="dropdown">الهيكل التنظيمي</li></Link>
                </ul>
            </div>
        </div>
    )
}
