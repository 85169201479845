import request from "../../utils/request";
import { toast } from "react-toastify";
import { rumorActions } from "../slices/rumorSlice";

// Fetch Rumors
export function fetchRumors() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/rumors")
            dispatch(rumorActions.setRumors(data));
            localStorage.setItem("rumors", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Fetch Admin Rumors
export function fetchAdminRumors() {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            const { data } = await request.get(`/adminDashboard/${userId}/rumors`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            dispatch(rumorActions.setRumors(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Update Rumors
export function updateRumors(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.put(`/adminDashboard/rumor/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(rumorActions.setRumor(data));
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Delete Rumors
export function deleteRumors(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.delete(`/adminDashboard/rumor/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(rumorActions.deleteRumor(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Rumor
export function addRumors(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const token = user.token;
            dispatch(rumorActions.setLoading());
            await request.post("/adminDashboard/addRumor", info, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(rumorActions.setIsRumorCreated());
            setTimeout(() => dispatch(rumorActions.clearIsRumorCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(rumorActions.clearLoading());
        }
    }
}