import React, { useState } from "react";
import { Footer } from "./../footer";
import { Header } from "./../header";
import "../../App.css";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/apiCalls/authApiCall";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const Settings = () => {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    // Submit Admin
    const onSubmit = (e) => {
        e.preventDefault();
        if (oldPassword.trim() === "") return toast.error("كلمة المرور الجديدة مطلوبة");
        if (password.trim() === "") return toast.error("يجب عليك تأكيد كلمة المرور الجديدة ");
        if (oldPassword.trim() !== password.trim()) return toast.error("كلمتي المرور غير متطابقتين");

        dispatch(updateUser({ password }));
        navigate('/')
    }

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
   
    return (
        <div className="login">
            <div className="div-2">
                <Header className="header-instance" element="image.png" line="image.svg" vector="vector-2.svg" />
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="text-wrapper-2">تغيير كلمة المرور</div>
                <form onSubmit={onSubmit} className="overlap-2">
                    <label htmlFor="oldPassword" className="text-wrapper55">أدخل كلمة المرور الجديدة:</label>
                    <input type="password" id="oldPassword" name="oldPassword" autoComplete="on" className="rectangle-2" placeholder="كلمة المرور الجديدة" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                    <label htmlFor="password" className="text-wrapper-4">تأكيد كلمة المرور الجديدة:</label>
                    <input type="password" id="password" name="password" autoComplete="on" className="rectangle" placeholder="تأكيد كلمة المرور الجديدة" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button className="text-wrapper-5 div-wrapper" type='submit'>تغيير</button>
                </form>
            </div>
            <Footer />
        </div>
    );
};
