import React, { useEffect } from "react";
import 'react-slideshow-image/dist/styles.css';
import { Fade, Zoom, Slide } from 'react-slideshow-image';
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../redux/apiCalls/newApiCall";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

export default function ImageSlider() {
    const dispatch = useDispatch();
    const { news } = useSelector(state => state.new)

    useEffect(() => {
        dispatch(fetchNews())
    }, [])

    const lastSevenNews = news.slice(-7)
    return (
        <div className="framCont" >
            <Fade>
                {lastSevenNews.map((item) => (
                    <Link to={`/new/${item._id}`}>
                        <div key={item.id}>
                            <img className='simg' alt={parse(`${item?.title}`)} src={`${item.image.url}`} >
                            </img>
                            <div className="newTit">{parse(`${item?.title}`)}</div>
                        </div>
                    </Link>
                ))}
            </Fade>
        </div>
    )
}
