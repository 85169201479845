import request from "../../utils/request";
import { toast } from "react-toastify";
import { memberActions } from "../slices/memberSlice";

// Fetch Members
export function fetchMembers() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/members")
            dispatch(memberActions.setMembers(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Fetch Admin Members
export function fetchAdminMembers() {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            const { data } = await request.get(`/adminDashboard/${userId}/members`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            dispatch(memberActions.setMembers(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Update Members
export function updateMembers(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.put(`/adminDashboard/member/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(memberActions.setMember(data));
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Delete Members
export function deleteMembers(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.delete(`/adminDashboard/member/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(memberActions.deleteMember(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Members
export function addMembers(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const token = user.token;
            dispatch(memberActions.setLoading());
            await request.post("/adminDashboard/addMember", info, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(memberActions.setIsMemberCreated());
            setTimeout(() => dispatch(memberActions.clearIsMemberCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(memberActions.clearLoading());
        }
    }
}