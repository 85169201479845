import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/authSlice";
import { newReducer } from "./slices/newSlice";
import { debateReducer } from "./slices/debateSlice";
import { encyclopediaReducer } from "./slices/encyclopediaSlice";
import { libraryReducer } from "./slices/librarySlice";
import { memberReducer } from "./slices/memberSlice";
import { rumorReducer } from "./slices/rumorSlice";


const store = configureStore({
    reducer:{
        auth: authReducer,
        new: newReducer,
        debate: debateReducer,
        encyclopedia: encyclopediaReducer,
        library: libraryReducer,
        member: memberReducer,
        rumor: rumorReducer,
    }
});

export default store;