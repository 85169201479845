import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDebates } from "../redux/apiCalls/debateApiCall";
import { Link } from "react-router-dom";
import moment from 'moment';
import parse from 'html-react-parser';


export const Debates = () => {
    const dispatch = useDispatch();
    const { debates } = useSelector(state => state.debate)

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        dispatch(fetchDebates())
    }, [])

    return (
        <div className="news">
            <div className="div-2">
                <div className="div-wrapper">
                    <div className="text-wrapper-3">المناظرات</div>
                </div>
                <div className="groupsContainer">
                    {debates.map((item) => (
                        <div className="group" key={item.id}>
                            <div className="date">{moment(item.date).format('LL')}</div>
                            <div className="rectangle">{parse(`${item.title}`)}</div>
                            <div className='videoCon'>
                                <video controls>
                                    <source src={item?.video.url} type="video/mp4" />
                                </video>
                            </div>
                            <div className="div">{parse(`${item.summary}`)}</div>
                            <div className="text-wrapper"><Link to={`/debate/${item._id}`}>اقرأ المزيد</Link></div>
                        </div>))}
                </div>
                <Header
                    className="header-instance"
                    element="306085283-3261402177510915-5362796939866915607-n-removebg-preview-1-2.png"
                    line="line-1-2.svg"
                    vector="vector-2.svg"
                />
                <Footer
                    className="footer-instance"
                    element="image.png"
                    email="email-2.svg"
                    img="line-7-2.svg"
                    line="image.svg"
                />
            </div>
        </div>
    );
};
