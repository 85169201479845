import React, { useEffect, useState } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDebates, showDebateSubject } from "../redux/apiCalls/debateApiCall";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';

export const DebateSubject = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { debates } = useSelector(state => state.debate)

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchDebates())
  }, [id]);

  const myDebate = debates.find((obj) => obj._id === id);

  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);



  return (
    <div className="subject">
      <div className="div-2">
        {
          <div className="subcont" key={id}>
            <div className="overlap-2">
              <div className="overlap-3">
                <div className="text-wrapper-2">{parse(`${myDebate?.title}`)}</div>
              </div>
              <div className="mainSub">
                <div className="date">{moment(myDebate?.date).format('LL')}</div>
                {
                  <div className='videoCon'>
                    <video controls>
                      <source src={myDebate?.video?.url} type="video/mp4" />
                    </video>
                  </div>
                }
                <p className="p">
                  {parse(`${myDebate?.subject}`)}
                </p>
              </div>
            </div>
          </div>
        }
        <Header className="header-instance" element="image.png" line="line-1-2.svg" vector="image.svg" />
      </div>
      <Footer
        className="footer-instance"
        element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
        email="email-2.svg"
        img="line-7-2.svg"
        line="line-6-2.svg"
      />
    </div>
  );
};
