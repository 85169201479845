import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner'
import { addLibraries } from '../../../redux/apiCalls/libraryApiCall';
import JoditEditor from 'jodit-react';


export default function AddLibraryScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isLibraryCreated } = useSelector(state => state.library);
    const editor = useRef(null);

    const [title, setTitle] = useState("");
    const [summary, setSummary] = useState("");
    const [link, setLink] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (link.trim() === "") return toast.error("رابط التحميل مطلوب");

        dispatch(addLibraries({title, summary, link}));
    }

    useEffect(() => {
        if (isLibraryCreated) {
            navigate("/dashboard/libraries");
        }
    }, [isLibraryCreated, navigate]);

    return (
        <div className='updateScreen'>
            {
                <form className='updateForm' onSubmit={onSubmit}>
                    <label htmlFor='title'>العنوان</label>
                    <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                    <label htmlFor='summary'>نبذة</label>
                    <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                    <label htmlFor='link'>رابط التحميل</label>
                    <textarea type='text' id='link' name='link' value={link} onChange={(e) => setLink(e.target.value)}></textarea>
                    <button className='sbmtBtn' type='submit'>
                        {
                            loading ? <RotatingLines
                                visible={true}
                                height="30"
                                width="30"
                                color="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /> :  "أضف"
                        }
                    </button>
                </form>
            }
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
