import React from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css"

export const Founders = () => {
    
  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    return (
        <div className="founders">
            <div className="div-2">
                <div className="overlap-2">
                    <img className="element-3" alt="Element" src="/imgs/1.png" />
                    <img className="element-4" alt="Element" src="/imgs/3.png" />
                    <div className="text-wrapper-2">فريق عمل مساحة قيمة المجتمعية</div>
                </div>
                <div className="foundersCont">
                    <div className="founs">
                        <div className="foun">
                            <img className="founImg" alt="علاء فاروق" src="/imgs/a.JPG" />
                            <div className="container">
                                <h3>علاء فاروق</h3>
                                <p>باحث وكاتب صحفي، عضو نقابة الصحفيين، حاصل على دبلوم المفاوضات الدولية اقتصاد وعلوم سياسية جامعة القاهرة، بدأ مجال المناظرات بداية من عام 2016 وشارك في المسابقة الوطنية للمناظرات والعديد من المناظرات التنافسية والعامة، حاصل على تدريب TOT من مؤسسة البشرية من أجل السلام، عضو مؤسس بمساحة قيمة المجتمعية، ومدرب مناظرات منذ 2017.</p>
                            </div>
                        </div><br></br>
                        <div className="founButh">
                            <img className="founImg" alt="بثينة حسن" src="/imgs/b.JPG" />
                            <div className="container">
                                <h3>بثينة حسن</h3>
                                <p>
                                    بكالوريوس الهندسة الطبية جامعة القاهرة، بدأت مجال المناظرات بداية من عام 2016، نائب مدير برنامج صوت الشباب العربي التابع للمجلس الثقافي البريطاني بمؤسسة فاضليا سابقا، وشاركت في العديد من المسابقات الوطنية والمعسكرات الخاصة بالمناظرات والعديد من المناظرات التنافسية والعامة، حاصلة على تدريب TOF للتيسير في مجال المناظرات بالمجلس الثقافي البريطاني، عضو مؤسس بمساحة قيمة المجتمعية، ومدرب مناظرات منذ 2017.
                                </p>
                            </div>
                        </div><br></br>
                        <div className="foun">
                            <img className="founImg" alt="حسام حسن" src="/imgs/h.JPG" />
                            <div className="container">
                                <h3>حسام حسن</h3>
                                <p>
                                    بكالوريوس تجارة ودبلومة إدارة الأعمال جامعة القاهرة، بدأ مجال المناظرات بداية من عام 2016، مدير برنامج صوت الشباب العربي التابع للمجلس الثقافي البريطاني بمؤسسة فاضليا سابقا، وشارك في العديد من المسابقات الوطنية والمعسكرات الخاصة بالمناظرات والعديد من المناظرات التنافسية والعامة، حاصل على تدريب TOT للتدريب في مجال المناظرات وتدريب المحكمين بالمناظرات بالمجلس الثقافي البريطاني، عضو مؤسس بمساحة قيمة المجتمعية، ومدرب مناظرات منذ 2016.
                                </p>
                            </div>
                        </div><br></br>
                    </div>
                </div>
                <Header className="header-instance" element="image.png" line="line-1-2.svg" vector="image.svg" />
            </div>
            <Footer
                className="footer-instance"
                element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
                email="email-2.svg"
                img="line-7-2.svg"
                line="line-6-2.svg"
            />
        </div>
    );
};
