import request from "../../utils/request";
import { toast } from "react-toastify";
import { libraryActions } from "../slices/librarySlice";

// Fetch Libraries
export function fetchLibraries() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/library")
            dispatch(libraryActions.setLibraries(data));
            localStorage.setItem("libraries", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Fetch Admin Libraries
export function fetchAdminLibraries() {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            const { data } = await request.get(`/adminDashboard/${userId}/library`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            dispatch(libraryActions.setLibraries(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Update Libraries
export function updateLibraries(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.put(`/adminDashboard/library/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                }
            });
            dispatch(libraryActions.setLibrary(data));
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Delete Libraries
export function deleteLibraries(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.delete(`/adminDashboard/library/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(libraryActions.deleteLibrary(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Libraries
export function addLibraries(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"));
            const userId = user._id;
            const token = user.token;
            dispatch(libraryActions.setLoading());
            console.log(info)
            await request.post(`/adminDashboard/${userId}/addLibrary`, info , {
                headers: {
                    Authorization: "Bearer " + token,
                }
            });
            dispatch(libraryActions.setIsLibraryCreated());
            setTimeout(() => dispatch(libraryActions.clearIsLibraryCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(libraryActions.clearLoading());
        }
    }
}