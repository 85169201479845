import { createSlice } from "@reduxjs/toolkit";

// encyclopediaSlice states and actions 
const encyclopediaSlice = createSlice({
    name:"encyclopedia",
    initialState: {
        encyclopedias: [],
        encyclopedia: null,
        loading: false,
        isEncyclopediaCreated: false,
    },
    reducers: {
        setEncyclopedias(state, action) {
            state.encyclopedias = action.payload;
        },
        setEncyclopedia(state, action) {
            state.encyclopedia = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsEncyclopediaCreated(state) {
            state.isEncyclopediaCreated = true;
            state.loading = false;
        },
        clearIsEncyclopediaCreated(state) {
            state.isEncyclopediaCreated = false;
        },
        deleteEncyclopedia(state, action) {
            state.encyclopedias = state.encyclopedias.filter(i => i._id !== action.payload);
        },
    }
});

const encyclopediaReducer = encyclopediaSlice.reducer;
const encyclopediaActions = encyclopediaSlice.actions;

export {encyclopediaActions, encyclopediaReducer}