import React, { useEffect, useLayoutEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import ImageSlider from "./partitions/imageSlider";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchDebates } from "../redux/apiCalls/debateApiCall";
import { fetchEncyclopedias } from "../redux/apiCalls/encyclopediaApiCall";
import { fetchLibraries } from "../redux/apiCalls/libraryApiCall";
import parse from 'html-react-parser';

const LandingPage = () => {
  const dispatch = useDispatch();
  const { debates } = useSelector(state => state.debate)
  const { encyclopedias } = useSelector(state => state.encyclopedia)
  const { libraries } = useSelector(state => state.library)

  setTimeout(() => window.scrollTo(0, 0), 0);

  useEffect(() => {
    dispatch(fetchDebates());
    dispatch(fetchEncyclopedias());
    dispatch(fetchLibraries());
  }, [])

  const lastSevenDebates = debates.slice(-7);
  const lastSevenEncycos = encyclopedias.slice(-7);
  const lastSevenLibrariess = libraries.slice(-7);

  return (
    <div className="landing-page">
      <div className="div-2">
        <img
          className="politicians-talking"
          alt="Politicians talking"
          src="/imgs/home.png"
        />
        <p className="qimaHome">نتناظر لإحياء مجتمع</p>
        <p className="qimaHomeTxt">قيمة هي مساحة مجتمعية تفاعلية تعمل على نشر وترسيخ ثقافة المناظرات.</p>
        <div className="overlap-5">
          <div className="ellipse-67">
            <div className="text-wrapper-4">الأخبار</div>
          </div>
          <div className="rectangle-14">
            <div className="frame">
              <ImageSlider className="imageSlider" />
            </div>
            <div className="overlap-6">
              <div className="text-wrapper-6"><Link to={"/news"}>كل الأخبار</Link></div>
            </div>
          </div>
        </div>
        <div className="overlap-7">
          <div className="relativecont">
            <div className="rectangle-17">
              <div className="text-wrapper-7">المناظرات</div>
            </div>
            <div className="ellipse-2">
              <div className="relativecont">
                <div className="staticcont">
                  <div className="frame-2">
                    {lastSevenDebates.map((item) => (
                      <div className="group-6" key={item?._id}>
                        <div className="staticde">
                          <div className="overlap-group-4">
                            <p className="rectangle-18">{parse(`${item?.title}`)}</p>
                            <div className="text-wrapper-9"><Link to={`/debate/${item?._id}`}>اقرأ المزيد</Link></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-wrapper-6 debate"><Link to={"/debates"}>كل المناظرات</Link></div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-8">
          <div className="relativecontEn">
            <div className="ellipse-3">
              <div className="text-wrapper-10">موسوعة قيمة الإلكترونية</div>
            </div>
            <div className="rectangle-20">
              <div className="staticEn">
                {lastSevenEncycos.map((item) => (
                  <div className="group-6enc" key={item?.id}>
                    <div className="overlap-group-4enc">
                      <div className="rectangle-18enc">{parse(`${item?.title}`)}</div>
                      <div className="div">{parse(`${item?.summary}`)}</div>
                      <div className="text-wrapper-9"><a href={item?.link}>رابط تحميل الملف</a></div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-wrapper-6 ency"><Link to={"/encyclopedia"}>أذهب إلى الموسوعة</Link></div>
            </div>
          </div>
        </div>
        <div className="overlap-8">
          <div className="relativecontEn">
            <div className="ellipse-3">
              <div className="text-wrapper-10">مكتبة قيمة الإلكترونية</div>
            </div>
            <div className="rectangle-20">
              <div className="staticEn">
                {lastSevenLibrariess.map((item) => (
                  <div className="group-6enc" key={item?.id}>
                    <div className="overlap-group-4enc">
                      <div className="rectangle-18enc">{parse(`${item?.title}`)}</div>
                      <div className="div">{parse(`${item?.summary}`)}</div>
                      <div className="text-wrapper-9"><a href={item?.link}>رابط تحميل الملف</a></div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-wrapper-6 ency"><Link to={"/library"}>أذهب إلى المكتبة</Link></div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        className="footer-instance"
        element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
        email="email-2.svg"
        img="line-7-2.svg"
        line="line-6-2.svg"
      />
      <Header
        className="header-instance"
        element="306085283-3261402177510915-5362796939866915607-n-removebg-preview-1-2.png"
        line="line-1-2.svg"
        vector="image.svg"
      />
    </div>
  );
};

export default LandingPage;
