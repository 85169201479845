import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css"

export const AboutDebates = () => {

  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

  return (
    <div className="aboutUs aboutdebates">
      <div className="div-2">

        <div className="secs">
          <div className="sec">
            <h2>عن المناظرات</h2>
            <p>لا نسعى بالمناظرات لذكر عيوب الآخر أو إظهار أي طرف من طرفي المناظرة في وضع الفائز أو الخاسر أو قوي أو ضعيف، فلا مجال للمكسب والخسارة بالمناظرات التي نقدمها ولا يسعي أي فريق من فريقي المناظرة للفوز، ولكن هناك ما هو أسمى من ذلك يسعى إليه كافة الأطراف بالمناظرة وهو دحض الباطل وإظهار الحقائق وتكذيب الشائعات وإعطاء المعلومة السليمة، والأهم هو الاستفادة والمعرفة لكل من يستمع للمناظرة.  <br></br>
              قال ابن منظور في لسان العرب والمناظرة "أن تناظر أخاك في أمر إذا نظرتما فيه معاً كيف تأتيانه"، وتعرف المناظرة في الاصطلاح " تعني تبادل الكلام والآراء المتعارضة في موضوع يثير الجدل كبعض الموضوعات السياسية أو الأدبية"، أما مساحة قيمة المجتمعية فترى المناظرات على أنها فعالية خطابية منظمة لها أشكال مختلفة &#40;تنافسية / عامة &#41; حول قضية جدلية معينة للتعريف بها وتوضيحها للغير للاستفادة والمعرفة، تتم ما بين فريقين وفقا لقواعد وضوابط محددة في إطار زمني محدد متساوي بينهما، يقدم كل طرف الحجج الخاص به محاولًا إثباتها بالدليل ودحض حجج الفريق الآخر بالدليل وتنتهي المناظرة بتصويت الجمهور &#40; عامة &#41; أو قرار لجنة التحكيم &#40; تنافسية &#41;.</p>
            <h4>توجد أنظمة مختلفة للمناظرات وأشهرها</h4>

            1- نموذج البرلمان البريطاني <br></br>

            2- نموذج كارل بوبر <br></br>

            3- نموذج مايس <br></br>

            <h4>
              أما في مساحة قيمة المجتمعية فتتبع نموذج خاص بها وهو نظام تم تطويره بواسطة قيمة ويتكون من:
            </h4>

            1- نموذج قيمة للمناظرات التدريبية <br></br>

            2- نموذج قيمة للمناظرات العامة<br></br>
          </div><br></br>
          <div className="sec">
            <h2>تاريخ المناظرات  </h2>
            <p>يعتقد الكثير أن المناظرات تعقد في البيئات الرسمية كالجامعات ومجلس النواب والبرامج التلفزيونية، ولكنها في الحقيقة تحدث وتظهر في أي مكان، بداية من طاولات العشاء مروراً بقسم التعليقات في مواقع التواصل الاجتماعي، ومثل هذه المناظرات تتم بشكل غير رسمي عن نظيرتها التي يتم الإعداد والتجهيز لها قبل تنفيذها، وهناك اختلافات عدة حول تحديد ومعرفة متى ظهرت المناظرات وكيف بدأت، فهناك من يرى أن المناظرة الأولى كانت ما بين الملائكة وإبليس حول السجود لأدم، كما تعود كتابة المناظرة في الأدب العالمي إلى الحضارات البابلية والأكادية والسومرية وبين النهرين حيث وجد أجزاء من المجادلات والمنازعات، ويرجح الكثير أن مباريات السجال الفكري التي دارت بين فلاسفة اليونان القديمة أو بين علماء الهند القديمة هي بداية مختلفة للمناظرات.</p>
            <p>وانتقلت المناظرات من ساحات الفكر الفلسفي إلى المحافل السياسية والدينية، حيث كانت تقام مناظرات في المساجد قديماً بين الفرق الدينية المختلفة، وظهرت المناظرات بشكل كبير في الناحية السياسية بداية من مجالس النواب ثم استخدامها كأداة فعالة في الانتخابات الرئاسية المختلفة لحسم مصير المرشحين، وكانت أول مناظرة تم عرضها على شاشات التلفزيون الخاصة بالانتخابات الرئاسية الأمريكية عام 1956.</p>
          </div><br></br>
          <div className="sec">
            <h2>أهداف المناظرات  </h2>
            <p>تهدف المناظرة إلى ترسيخ مبدأ تقبل الرأي والرأي الآخر والبعد عن حجر الآراء والأفكار والتشدد فيهما، كما أنها توفر كافة المعلومات عن القضايا المختلفة لزيادة الوعي بها لمزيد من التنوع المعرفي والثقافي خارج نطاق معرفة المتناظرين، والوصول إلى آليات حل لها يمكن تطبيقها.</p>
            <p>وتعتبر المناظرة أداة دحض ونفي أي شائعة أو معلومات خاطئة وتقديم صحيح المعلومة للعلم بالأدلة الموثقة والسليمة ليستفيد منها الجميع، وبجانب كل هذا فتعتبر المناظرات صوت الفئات المهمشة وصوت من لا صوت له فتعمل على إظهار تلك الفئات والتعريف بها للمجتمع وبقضاياها المختلفة والعمل على تقليل الفجوة بين الطبقات والفئات المختلفة بالمجتمع.</p>
          </div><br></br>
          <div className="sec">
            <h2>فوائد المناظرات</h2>
            <p>
              لا تساعد المناظرات الأفراد في اكتساب مهارات الحوار والتواصل الجيد فقط، بل تعد أفضل وسيلة لصقل مهارات التفكير النقدي والتحليلي، وتساعد الأفراد على تطوير مهارات الخطابة والارتجال، وبما أن المناظرات تتطلب بحث وتعمق في الموضوع محل المناظرة فهذا بدوره يعزز وينمي مهارات البحث العلمي والاطلاع لدى المتناظرين، إضافة إلى ذلك فالمناظرات تساعد المتناظرين على التمكن من استخدام اللغة العربية الفصحى والتحدث بها بطلاقة.
            </p>
            <p>ولا يقتصر فوائد المناظرات فقط على الأفراد بل نشر وترسيخ ثقافة المناظرات له فوائد وأهمية تنعكس على المجتمع بأسره، حيث عرض القضايا المجتمعية المثيرة للجدل في إطار التناظر يجعلنا نصل لحلول لتلك القضايا بعد طرح الحقائق العلمية المتعلقة بها، والابتعاد التام عن الأقاويل والشائعات التي تنتشر بسرعة البرق في المجتمع، وهذا بدوره يقلل التعصب بين أفراد المجتمع الواحد ويساعد في تكوين وجهات نظر سليمة تجاه تلك القضايا.
            </p>
            <p>
              هنا في قيمة نؤمن بأهمية الاستمرارية والاستدامة، فلا يكون التدريب بهدف التمكن من فن المناظرات فحسب، بل تظل عملية التدريب مستمرة تتضمن اكتساب الكثير من المهارات كسرعة البديهة، والعمل الجماعي، وتطوير القدرة على بناء وتنظيم الأفكار، وتعزيز الثقة بالنفس، والمهارات القيادية وغيرها ذلك على جانب الفرد وكذلك للمجتمع فشعار قيمة " نتناظر لإحياء مجتمع" لإيماننا العميق بأن فن المناظرات وسيلة هامة لإحياء هذا المجتمع مرة أخرى من غفوته التي طال فيها ذلك عن طريق عرض قضاياه في إطار سليم ومنطقي ومنظم وإيجاد حلول فعالة وعملية قابلة للتنفيذ.
            </p>
          </div><br></br>
        </div>
        <Header />

      </div>
      <Footer />
    </div >
  );
};