import { createSlice } from "@reduxjs/toolkit";

// rumorSlice states and actions 
const rumorSlice = createSlice({
    name:"rumor",
    initialState: {
        rumors: [],
        rumor: null,
        loading: false,
        isRumorCreated: false,
    },
    reducers: {
        setRumors(state, action) {
            state.rumors = action.payload;
        },
        setRumor(state, action) {
            state.rumor = action.payload;
        },
        setLoading(state) {
            state.loading = true;
        },
        clearLoading(state) {
            state.loading = false;
        },
        setIsRumorCreated(state) {
            state.isRumorCreated = true;
            state.loading = false;
        },
        clearIsRumorCreated(state) {
            state.isRumorCreated = false;
        },
        deleteRumor(state, action) {
            state.rumors = state.rumors.filter(i => i._id !== action.payload);
        },
    }
});

const rumorReducer = rumorSlice.reducer;
const rumorActions = rumorSlice.actions;

export {rumorActions, rumorReducer}