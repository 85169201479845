import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchLibraries } from "../redux/apiCalls/libraryApiCall";
import parse from 'html-react-parser';

export const Library = () => {
  const dispatch = useDispatch();
  const { libraries } = useSelector(state => state.library)

  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

  useEffect(() => {
    dispatch(fetchLibraries())
  }, [])

  return (
    <div className="encyclopedia">
      <div className="div-2">
        <div className="div-wrapper">
          <div className="text-wrapper-2">مكتبة قيمة الإلكترونية</div>
        </div>
        <div className="groupscont">
          {libraries.map((item) => (
            <div className="group" key={item.id}>
              <div className="rectangle">{parse(`${item.title}`)}</div>
              <div className="div">{parse(`${item.summary}`)}</div>
              <div className="text-wrapper"><a href={item.link}>رابط تحميل الملف</a></div>
            </div>))}
        </div>
        <Header
          className="header-instance"
          element="306085283-3261402177510915-5362796939866915607-n-removebg-preview-1-2.png"
          line="line-1-2.svg"
          vector="vector-2.svg"
        />
        <Footer
          className="footer-instance"
          element="image.png"
          email="email-2.svg"
          img="line-7-2.svg"
          line="image.svg"
        />
      </div>
    </div>
  );
};
