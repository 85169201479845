import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchEncyclopedias } from "../redux/apiCalls/encyclopediaApiCall";
import parse from 'html-react-parser';

export const Encyclopedia = () => {
  const dispatch = useDispatch();
  const { encyclopedias } = useSelector(state => state.encyclopedia)

  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

  useEffect(() => {
    dispatch(fetchEncyclopedias())
  }, [])

  return (
    <div className="encyclopedia">
      <div className="div-2">
        <div className="div-wrapper">
          <div className="text-wrapper-2">موسوعة قيمة الإلكترونية</div>
        </div>
        <div className="groupscont">
          {encyclopedias.map((item) => (
            <div className="group" key={item?.id}>
              <div className="rectangle">{parse(`${item.title}`)}</div>
              <div className="div">{parse(`${item.summary}`)}</div>
              <div className="text-wrapper"><a href={item?.link}>رابط تحميل الملف</a></div>
            </div>))}
        </div>
        <Footer />
        <Header />

      </div>
    </div>
  );
};
