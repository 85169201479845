import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../redux/apiCalls/newApiCall";
import { Link } from "react-router-dom";
import moment from 'moment';
import parse from 'html-react-parser';

export const News = () => {
    const dispatch = useDispatch();
    const { news } = useSelector(state => state.new)

    setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    useEffect(() => {
        window.onload = () => {
            window.scrollTo(0, 0);
        }
        dispatch(fetchNews())
    }, []);

    return (
        <div className="news">
            <div className="div-2">

                <div className="div-wrapper">
                    <div className="text-wrapper-3">الأخبار</div>
                </div>
                <div className="groupsContainer">
                    {news.map((item) => (
                        <div className="group" key={item?.id}>
                            <div className="date">{moment(item?.date).format('LL')}</div>
                            <div className="rectangle">{parse(`${item?.title}`)}</div>
                            <img className="newImg" alt={item?.image.original_filename} src={item?.image.url}></img>
                            <div className="div">{parse(`${item?.summary}`)}</div>
                            <div className="text-wrapper"><Link to={`/new/${item?._id}`}>اقرأ المزيد</Link></div>
                        </div>))}
                </div>
                <Header
                    className="header-instance"
                    element="306085283-3261402177510915-5362796939866915607-n-removebg-preview-1-2.png"
                    line="line-1-2.svg"
                    vector="vector-2.svg"
                />
                <Footer
                    className="footer-instance"
                    element="image.png"
                    email="email-2.svg"
                    img="line-7-2.svg"
                    line="image.svg"
                />
            </div>

        </div>
    );
};
