import React from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "../App.css"

export const OrganizationalChart = () => {
    
  setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);

    return (
        <div className="founders">
            <div className="div-2">
                <div className="overlap-2">
                    <img className="element-3" alt="Element" src="/imgs/1.png" />
                    <img className="element-4" alt="Element" src="/imgs/3.png" />
                    <div className="text-wrapper-2">الهيكل التنظيمي</div>
                </div>
                <img className="OCImg" alt="الهيكل التنظيمي" src="/imgs/OrganizationalChart.png" />
                <Header className="header-instance" element="image.png" line="line-1-2.svg" vector="image.svg" />
            </div>
            <Footer
                className="footer-instance"
                element="241818951-2984719715179164-1542959006918957827-n-removebg-preview-1-2.png"
                email="email-2.svg"
                img="line-7-2.svg"
                line="line-6-2.svg"
            />
        </div>
    );
};
