import './App.css';
import { BrowserRouter as Router, Routes, Route, Switch, Link, Navigate } from "react-router-dom";
import LandingPage from './pages/landingPage'
import { News } from './pages/news';
import { RMCenter } from './pages/RMCenter';
import { AboutUs } from './pages/aboutUs';
import { Encyclopedia } from './pages/encyclopedia';
import { Library } from './pages/library';
import { Debates } from './pages/debates';
import { NewSubject } from './pages/newSubject';
import { Login } from './pages/dashboard/login';
import { useSelector } from 'react-redux';
import { DashboardNews } from './pages/dashboard/dashboardNews';
import { Settings } from './pages/dashboard/settings';
import { DashboardDebates } from './pages/dashboard/dashboardDebates';
import { DashboardEncyco } from './pages/dashboard/dashboardEncyco';
import { DashboardLibrary } from './pages/dashboard/dashboardLibrary';
import { DashboardRumors } from './pages/dashboard/dashboardRumors';
import { DebateSubject } from './pages/debateSubject';
import AddNewScreen from './pages/dashboard/partitions/addNewScreen';
import UpdateNewScreen from './pages/dashboard/partitions/updateNewScreen';
import UpdateDebateScreen from './pages/dashboard/partitions/updateDebateScreen';
import AddRumorScreen from './pages/dashboard/partitions/addRumorScreen';
import AddLibraryScreen from './pages/dashboard/partitions/addLibraryScreen';
import AddEncyclopediaScreen from './pages/dashboard/partitions/addEncyclopediaScreen';
import AddDebateScreen from './pages/dashboard/partitions/addDebateScreen';
import UpdateRumorScreen from './pages/dashboard/partitions/updateRumorScreen';
import UpdateEncycoScreen from './pages/dashboard/partitions/updateEncycoScreen';
import UpdateLibraryScreen from './pages/dashboard/partitions/updateLibraryScreen';
import { Founders } from './pages/founders';
import { OrganizationalChart } from './pages/organizationalChart';
import { AboutDebates } from './pages/aboutDebates';


function App() {

  const { user } = useSelector(state => state.auth)
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/news" exact element={<News />} />
          <Route path="/rumor-monitoring-centre" exact element={<RMCenter />} />
          <Route path="/about-debates" exact element={<AboutDebates />} />
          <Route path="/aboutus" exact element={<AboutUs />} />
          <Route path="/founders" exact element={<Founders />} />
          <Route path="/organizational-chart" exact element={<OrganizationalChart />} />
          <Route path="/encyclopedia" exact element={<Encyclopedia />} />
          <Route path="/library" exact element={<Library />} />
          <Route path="/debates" exact element={<Debates />} />
          <Route path="/new/:id" exact element={<NewSubject />} />
          <Route path="/debate/:id" exact element={<DebateSubject />} />
          <Route path="/login" exact element={!user ? <Login /> : <Navigate to="/dashboard/news" />} />
          <Route path="/settings" exact element={user?.isAdmin ? <Settings /> : <Navigate to="/" />} />
          <Route path="/dashboard/news" exact element={user?.isAdmin ? <DashboardNews /> : <Navigate to="/" />} />
          <Route path="/dashboard/debates" exact element={user?.isAdmin ? <DashboardDebates /> : <Navigate to="/" />} />
          <Route path="/dashboard/libraries" exact element={user?.isAdmin ? <DashboardLibrary /> : <Navigate to="/" />} />
          <Route path="/dashboard/encyclopedia" exact element={user?.isAdmin ? <DashboardEncyco /> : <Navigate to="/" />} />
          <Route path="/dashboard/rumors" exact element={user?.isAdmin ? <DashboardRumors /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateNew/:id" exact element={user?.isAdmin ? <UpdateNewScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateDebate/:id" exact element={user?.isAdmin ? <UpdateDebateScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateRumor/:id" exact element={user?.isAdmin ? <UpdateRumorScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateEncyco/:id" exact element={user?.isAdmin ? <UpdateEncycoScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/updateLibrary/:id" exact element={user?.isAdmin ? <UpdateLibraryScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/addNew" exact element={user?.isAdmin ? <AddNewScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/addDebate" exact element={user?.isAdmin ? <AddDebateScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/addRumor" exact element={user?.isAdmin ? <AddRumorScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/addLibrary" exact element={user?.isAdmin ? <AddLibraryScreen /> : <Navigate to="/" />} />
          <Route path="/dashboard/addEncyclopedia" exact element={user?.isAdmin ? <AddEncyclopediaScreen /> : <Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
