import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchAdminLibraries, updateLibraries } from '../../../redux/apiCalls/libraryApiCall';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

export default function UpdateLibraryScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { libraries } = useSelector(state => state.library)
    const editor = useRef(null);

    useEffect(() => {
        dispatch(fetchAdminLibraries())
    }, [])

    const library = libraries.find((item) => item._id === id);

    const [title, setTitle] = useState(library.title)
    const [summary, setSummary] = useState(library.summary)
    const [link, setLink] = useState(library.link)

    const subjectId = library._id;


    const onSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === "") return toast.error("العنوان مطلوب");
        if (summary.trim() === "") return toast.error("النبذة مطلوبة");
        if (link.trim() === "") return toast.error("رابط التحميل مطلوب");

        dispatch(updateLibraries(subjectId, { 'title': title, 'summary': summary, 'link': link }));
        navigate("/dashboard/libraries");
    }

    return (
        <div className='updateScreen' key={library?.id}>
            <form className='updateForm' onSubmit={onSubmit}>
                <label htmlFor='title'>العنوان</label>
                <JoditEditor ref={editor} id='title' name='title' placeholder='أدخل العنوان.....' value={title} onChange={content => setTitle(content)} />
                <label htmlFor='summary'>نبذة</label>
                <JoditEditor ref={editor} id='summary' name='summary' placeholder='أدخل النبذة.....' value={summary} onChange={content => setSummary(content)} />
                <label htmlFor='link'>رابط التحميل</label>
                <textarea type='text' id='link' name='link' value={link} onChange={(e) => setLink(e.target.value)}></textarea>
                <button className='sbmtBtn' type='submit'>حفظ التعديلات</button>
            </form>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
