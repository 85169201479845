import { authActions } from "../slices/authSlice";
import request from "../../utils/request";
import { toast } from "react-toastify";

// Login User
export function loginUser(user) {
    return async (dispatch) => {
        try {
            const { data } = await request.post("/adminDashboard/loginAdmin", user)
            dispatch(authActions.login(data));
            localStorage.setItem("userInfo", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Logout User
export function logoutUser() {
    return (dispatch) => {
        dispatch(authActions.logout());
        localStorage.removeItem("userInfo");
    }
}


// Update User
export function updateUser(info) {
    return async () => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            await request.put(`/adminDashboard/updateUser/${userId}`, info, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            toast.done("Password has been changed successfully")
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}