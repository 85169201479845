import request from "../../utils/request";
import { toast } from "react-toastify";
import { encyclopediaActions } from "../slices/encyclopediaSlice";

// Fetch Encyclopedias
export function fetchEncyclopedias() {
    return async (dispatch) => {
        try {
            const { data } = await request.get("/encyclopedia");
            dispatch(encyclopediaActions.setEncyclopedias(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Fetch Admin Encyclopedias
export function fetchAdminEncyclopedias() {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            const userId = user._id;
            const token = user.token;
            const { data } = await request.get(`/adminDashboard/${userId}/encyclopedia`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            dispatch(encyclopediaActions.setEncyclopedias(data));
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}


// Update Encyclopedias
export function updateEncyclopedias(subjectId, updates) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.put(`/adminDashboard/encyclopedia/${subjectId}`, updates, {
                headers: {
                    Authorization: "Bearer " + token,
                }
            });
            dispatch(encyclopediaActions.setEncyclopedia(data));
            toast.success("تم التعديل بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Delete Encyclopedias
export function deleteEncyclopedias(subjectId) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"))
            // const userId = user._id;
            const token = user.token;
            const { data } = await request.delete(`/adminDashboard/encyclopedia/${subjectId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(encyclopediaActions.deleteEncyclopedia(data.subjectId));
            toast.success("تم الحذف بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    }
}

// Add Encyclopedias
export function addEncyclopedias(info) {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem("userInfo"));
            const userId = user._id;
            const token = user.token;
            dispatch(encyclopediaActions.setLoading());
            await request.post(`/adminDashboard/${userId}/addEncyclopedia`, info, {
                headers: {
                    Authorization: "Bearer " + token,
                }
            });
            dispatch(encyclopediaActions.setIsEncyclopediaCreated());
            setTimeout(() => dispatch(encyclopediaActions.clearIsEncyclopediaCreated()), 2000);
            toast.success("تمت الإضافة بنجاح");
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(encyclopediaActions.clearLoading());
        }
    }
}